import {
  completeInteraction,
  createOrFindClientInteractionLog,
} from '@/common/modules/clientInteractionLog'
import * as Cookies from 'js-cookie'
import { mapGetters } from 'vuex'

export const logNavigationMixin = {
  computed: {
    ...mapGetters('account', ['account']),
    isNotAdminUser: () => {
      return sessionStorage.getItem('admin-logged-in') !== 'true' &&
        Cookies.get('admin') !== 'logged_in_as_client'
    },
  },
  methods: {
    createAndCompleteLog(category, subCategory, interaction) {
      try {
        if (this.isNotAdminUser) {
          createOrFindClientInteractionLog({
            category: category,
            subCategory: subCategory,
            version: 1,
          }).then((res) => {
            completeInteraction({
              id: res.id,
              interaction: interaction,
              completed: true,
              object_table: 'Account',
              object_id: this.account.id,
            })
          })
        }
      } catch (error) {
        console.error(error)
      }
    },
    logNavigation(to, category, subCategory, interaction) {
      this.createAndCompleteLog(category, subCategory, interaction)
      this.$router.push(to)
    },
  },
}
