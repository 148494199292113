import { render, staticRenderFns } from "./DashpanelItem.vue?vue&type=template&id=305e5565&scoped=true"
import script from "./DashpanelItem.vue?vue&type=script&lang=js"
export * from "./DashpanelItem.vue?vue&type=script&lang=js"
import style0 from "./DashpanelItem.vue?vue&type=style&index=0&id=305e5565&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "305e5565",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/src/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('305e5565')) {
      api.createRecord('305e5565', component.options)
    } else {
      api.reload('305e5565', component.options)
    }
    module.hot.accept("./DashpanelItem.vue?vue&type=template&id=305e5565&scoped=true", function () {
      api.rerender('305e5565', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/DashpanelItem.vue"
export default component.exports