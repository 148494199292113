var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "item-container m-3" },
    [
      _c(
        "b-link",
        {
          staticClass: "dashpanel-link",
          class: { "badge-border": _vm.isShowBadge },
          attrs: { "aria-label": _vm.title + " Link" },
          on: {
            click: function ($event) {
              return _vm.logNavigation(
                _vm.linkTo,
                "dashpanel",
                "navigation",
                _vm.interaction
              )
            },
          },
        },
        [
          _vm.isShowBadge
            ? _c(
                "b-badge",
                {
                  staticClass:
                    "dashpanel-badge d-flex align-items-center justify-content-center",
                  attrs: { variant: "danger" },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.simplifiedNumber(_vm.badge)) +
                      "\n    "
                  ),
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "dashpanel-icon d-flex align-items-center justify-content-center mb-4",
            },
            [
              _c("b-img", {
                attrs: { src: "/images/dashpanel/" + _vm.typeIcons[_vm.type] },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mb-5 label" }, [
            _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }